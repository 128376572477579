#header-logo { font-size: 18px; font-weight: bold; }
#header-app-path { font-size: 12px }

#sibe-header-light * { background-color: #2a2a2a; color: #dedede; }
.header-menu-light * { background-color: #2a2a2a; color: #dedede; }
#sibe-header-dark * { background-color: #dedede; color: #2a2a2a; }
.header-menu-light * { background-color: #dedede; color: #2a2a2a; }


#sibe-header-light { background-color: #2a2a2a; color: #dedede; }
.header-menu-light * { background-color: #2a2a2a; color: #dedede; }

#sibe-header-dark { background-color: #dedede; color: #2a2a2a; }
.header-menu-dark * { background-color: #dedede; color: #2a2a2a; }
