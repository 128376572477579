main > div {
  background-color: #FFF;
  box-shadow: 0px 0px 15px #0004;
}

#leadbox {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 90vw;
     height: 90vh;
     background-color: #fff;
     border: 0;
     
     box-shadow: 0px 0px 15px #0000;
     /* box-shadow: 0px 0px 15px #0004; */  
}

#modal {  
  outline: none;
  border: 0;
}